import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import host from "../../../host/Host";

export const CategoryApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: `${host}/api/superAdmin/category` }),
  reducerPath: "CategoryApi",
  endpoints: (build) => ({
    getCategories: build.query({
      query: (page) => `/show?page=${page}`,
      transformResponse: (response) => response.categories,
      providesTags: ["Category"],
    }),
    getCategoriesWithoutPagination: build.query({
      query: () => `/showCategory`,
      transformResponse: (response) => response.categories,
      providesTags: ["Category"],
    }),
    getCategoriesStudio: build.query({
      query: () => `/showStudio`,
      transformResponse: (response) => response.makeup,
      providesTags: ["Category"],
    }),
    getCategoriesMakeUp: build.query({
      query: () => `/showMakeup`,
      transformResponse: (response) => response.makeup,
      providesTags: ["Category"],
    }),
    saveCategory: build.mutation({
      query: (categoryData) => ({
        url: `/save`,
        method: "POST",
        body: categoryData,
      }),
      invalidatesTags: ["Category"],
    }),
    updateCategory: build.mutation({
      query: ({ id, categoryData }) => ({
        url: `/update/${id}`,
        method: "POST",
        body: categoryData,
      }),
      invalidatesTags: ["Category"],
    }),
    deleteCategory: build.mutation({
      query: (id) => ({
        url: `/delete/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["Category"],
    }),
    updateCategoryStatus: build.mutation({
      query: (id) => ({
        url: `/updateStatus/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["Category"],
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useGetCategoriesWithoutPaginationQuery,
  useGetCategoriesStudioQuery,
  useGetCategoriesMakeUpQuery,
  useSaveCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useUpdateCategoryStatusMutation,
} = CategoryApi;
